import { React, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import AppRoutes from './Routes';

function App() {
  useEffect(() => {
    const checkDatabaseConnection = async () => {
      try {
        const response = await fetch('/.netlify/functions/fetchData'); // Call your serverless function
        const data = await response.json();
        
        // Log the response from the serverless function
        console.log(data.message || data.error); // Logs either the success or error message
      } catch (error) {
        console.error('Error calling serverless function:', error); // Logs any fetch errors
      }
    };

    checkDatabaseConnection(); // Call the function immediately on component mount
  }, []);
  
  return (
    <div className="App">
      <Router>
        <AppRoutes />
      </Router>
    </div>
  );
}

export default App;
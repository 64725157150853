import React from "react";
import "./Footer.css"; // Optional: Add CSS for the footer

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-left">
                    <div className="footer-section">
                        <h4>Find Us</h4>
                        <p>123 Main Street, Anytown, USA</p>
                    </div>
                    <div className="footer-section">
                        <h4>Contact Us</h4>
                        <p>Email: <a href="mailto:info@zaia.com">info@zaia.com</a></p>
                        <p>Phone: <a href="tel:+1234567890">(123) 456-7890</a></p>
                    </div>
                </div>
                <div className="footer-right">
                    <div className="footer-section">
                        <h4>Developed By</h4>
                        <p>Yakhoub Soumare: <a href="https://www.linkedin.com/in/yakhoub-soumare-2019/" target="_blank" rel="noopener noreferrer">LinkedIn</a></p>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Zaia. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
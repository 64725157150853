import React from "react";

const Ansiktsbehandlingar = () => {
    return (
        <>
            <h1>Ansiktsbehandlingar</h1>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras cursus iaculis dolor. Donec in suscipit mi. Vivamus sollicitudin urna eget massa porttitor, non molestie lorem pharetra. Duis iaculis nibh arcu, id congue mi pharetra quis. Nullam venenatis enim in mauris consectetur, vel ornare tellus aliquam. Ut mollis facilisis metus. Suspendisse enim magna, dignissim sed arcu ac, dignissim interdum purus. Sed sem sapien, egestas ut tincidunt fermentum, pellentesque sed turpis. Etiam commodo, lacus vitae efficitur accumsan, diam sem tempor dui, in ultricies eros lorem at mi. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed id efficitur sapien. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Fusce at aliquet libero.

                Donec in orci massa. Nullam tempor nibh a neque sollicitudin cursus. Aenean vitae dignissim velit, ac ornare ligula. Suspendisse vel egestas velit. Integer ipsum orci, varius et molestie commodo, pretium ut risus. In sed ante sed mauris malesuada rutrum. Nam elementum ipsum eget lacus pharetra, ac maximus arcu placerat.

                Nullam a nunc vitae quam vulputate imperdiet. Donec vitae justo ut dolor volutpat cursus. Nulla a commodo diam. Vivamus tempus turpis nec leo hendrerit efficitur. Fusce facilisis erat eget sagittis pulvinar. Donec et congue sapien, in tincidunt dui. Donec non quam justo. Integer maximus est non sem tempus rutrum. Fusce id tortor nisl. Quisque sit amet luctus nisl.

                Nam nec convallis urna. Phasellus congue, ligula vel facilisis lobortis, diam neque rhoncus sapien, et feugiat enim enim ut ipsum. Praesent sit amet nulla eget libero mattis volutpat. Praesent tortor ipsum, eleifend ut accumsan nec, tempor id lorem. Phasellus sit amet nisl bibendum, finibus tortor facilisis, suscipit odio. Sed tempus arcu a pharetra efficitur. Phasellus aliquam rhoncus orci sit amet sollicitudin.

                Nulla at nisi dui. Curabitur quis magna enim. Nullam turpis ipsum, sollicitudin eget placerat sed, commodo a mauris. Maecenas maximus augue mauris, at euismod nisl dignissim rutrum. Integer ut sollicitudin mauris. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur et posuere nisi.

                Etiam suscipit scelerisque massa, in faucibus lorem. Nunc eget suscipit lorem. Phasellus vel massa purus. Aliquam vestibulum nulla ac odio sagittis pretium. Aliquam erat volutpat. Aenean elementum pulvinar massa, at luctus justo eleifend at. Vestibulum hendrerit rutrum nulla, id pulvinar turpis interdum sed. Suspendisse potenti. Nunc egestas mattis tellus eget mattis. Aenean quam nulla, facilisis ac sapien varius, volutpat egestas mauris. Maecenas lobortis nisi et tellus semper, nec faucibus tortor molestie. Nam eu enim lorem. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed ac enim bibendum orci molestie pulvinar. Morbi pretium, nunc ac mattis eleifend, neque erat auctor nulla, sit amet faucibus turpis enim vel dolor.

                Vivamus justo urna, elementum at vestibulum sit amet, lacinia vitae odio. Integer odio turpis, elementum ac felis eget, consequat gravida massa. Quisque sit amet blandit turpis. Donec commodo rutrum lacus in finibus. Mauris eget quam vulputate, tempor ipsum ac, tincidunt tortor. Nulla sed justo a ex tristique finibus. Aenean et malesuada est. Proin ex lorem, elementum vel lacus a, aliquam finibus diam. Nulla odio sem, pretium vitae pharetra id, rutrum sed dui. Ut convallis dolor et nibh mollis, id elementum sem convallis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam sit amet lacus sed sem posuere auctor.

                Pellentesque in nisl eu lectus ornare gravida eget sit amet nisl. Nunc porta lorem vitae ultricies tempor. Aliquam consectetur magna leo, et ultrices lorem egestas eget. Donec mattis arcu at risus egestas sodales. Sed in nunc nec mi tempus sollicitudin. Aliquam erat volutpat. Quisque venenatis magna mauris, ut efficitur odio convallis eu. Cras in molestie turpis. Donec laoreet sapien id luctus aliquet. Duis gravida molestie mauris, at viverra diam congue at. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Integer venenatis nibh vel urna iaculis, ac vehicula sem venenatis. Quisque ac purus a erat tincidunt condimentum venenatis at arcu.

                Mauris facilisis placerat blandit. Nam magna mauris, iaculis condimentum dignissim sed, maximus in tellus. Nam non ligula leo. Maecenas neque dolor, faucibus sed egestas et, posuere ac sem. Nam erat sapien, porttitor eget ex et, tempus feugiat eros. Duis in tortor tincidunt, sollicitudin lacus a, malesuada massa. Integer in enim sed mauris efficitur pharetra. Proin a velit in dolor tristique semper at non ante. Quisque a molestie nisi. Mauris leo magna, ornare in condimentum sed, tincidunt in leo. Vestibulum eu arcu molestie, volutpat eros vitae, tristique velit.

                Curabitur nec erat ac neque dapibus dictum vitae in dui. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin bibendum tincidunt dictum. Aliquam finibus rutrum leo. Proin eu odio ipsum. Curabitur id venenatis dolor, in ornare eros. Quisque eu vestibulum dui, sit amet accumsan tortor. Quisque ex arcu, rutrum eget nisl quis, bibendum malesuada tellus. Donec eu laoreet ipsum. Aenean a facilisis leo, et fringilla quam. Fusce a diam at sem interdum eleifend viverra id velit.
            </p>
        </>
    );
}

export default Ansiktsbehandlingar;
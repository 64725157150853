import React, { useEffect } from "react";
import { Route, Routes, useLocation, Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';

import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Massage from "./components/Massage";
import Ansiktsbehandlingar from "./components/Ansiktsbehandlingar";
import FransarOchBryn from "./components/FransarOchBryn";
import Vaxning from "./components/Vaxning";
import Footer from "./components/Footer";

import "./App.css";

// const tableName = `about`;
// const columnName = `content`;

const AppRoutes = () => {
    const location = useLocation();
    // const [textData, setTextData] = useState(null);

    // Scroll to top when the route changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    // useEffect(() => {
    //     const fetchData = async (tableName, columnName) => {
    //         try {
    //             const response = await fetch(`/.netlify/functions/fetchData?tableName=${tableName}&columnName=${columnName}`);
                
    //             // Check if the response is not OK (status code 200-299)
    //             if (!response.ok) {
    //                 console.error('Error fetching data:', response.status, response.statusText);
    //                 return;
    //             }
    
    //             const text = await response.text(); // Get the response as text
    //             console.log('Response text:', text); // Log the response text
    
    //             // Try to parse the text as JSON
    //             try {
    //                 const data = JSON.parse(text);
    //                 setTextData(data);
    //             } catch (jsonError) {
    //                 console.error('Error parsing JSON:', jsonError);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };
    
    //     fetchData(tableName, columnName);
    // }, []);

    // useEffect(() => {
    //     if (textData) {
    //         console.log(textData);
    //     }
    // }, [textData]);

    // Directly use image paths without state
    const imageUrls = {
        logo: '/images/zaia-logo.svg',
        lightBackground: '/images/bakgrund-light.webp',
        massage: '/images/massage.webp',
        ansikte: '/images/ansikte.webp',
        fransarBryn: '/images/fransar-bryn.webp',
        vaxning: '/images/vaxning.webp'
    };

    return (
        <>
            <Navbar logoUrl={imageUrls.logo}/>
            <div className="background-container">
                <LazyLoad height={200} offset={100}>
                    <img src={imageUrls.lightBackground} alt="Background" className="background-image" />
                </LazyLoad>
                <div className="overlay-content">
                    <Link to="/kontakta-oss" className="overlay-button">Kontakta oss</Link>
                    <div className="vertical-line"></div>
                    <Link to="/om-oss" className="overlay-button">Om oss</Link>
                    <div className="vertical-line"></div>
                    <a href="https://business.bokadirekt.se/bransch/massage" target="_blank" rel="noopener noreferrer" className="overlay-button">Boka tid</a>
                </div>
            </div>
            <div className="content-container">
                <Routes>
                    <Route path="/" element={<Home imageUrls={imageUrls} />} />
                    <Route path="/massage" element={<Massage />} />
                    <Route path="/ansiktsbehandlingar" element={<Ansiktsbehandlingar />} />
                    <Route path="/fransar-och-bryn" element={<FransarOchBryn />} />
                    <Route path="/vaxning" element={<Vaxning />} />
                </Routes>
            </div>
            <Footer />
        </>
    );
}

export default AppRoutes;
